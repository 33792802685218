<template>
  <div class="custom-check">
    <el-button type="text" icon="el-icon-document" @click="openFn">{{ title }}</el-button>
    <!-- 弹框 -->
    <Modal :modalConfig="modalConfig" ref="modalForm" @onClose="closeModal">
      <div class="list-main">
        <div class="list-item">
          <div class="list-des">未选择</div>
          <el-scrollbar
            wrap-class="modal-scrollbar"
            class="dialog-style"
            style="height: 428px"
            :native="false"
            view-class="modal-container"
            :noresize="false"
            tag="div"
          >
            <div class="list">
              <el-checkbox-group v-model="checked" @change="checkedChange">
                <el-checkbox v-for="(item, k) in list" :label="item[configs.value]" :key="k">{{
                  item[configs.label]
                }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </el-scrollbar>
        </div>
        <div class="list-item">
          <div class="list-des">已选择</div>
          <el-scrollbar
            wrap-class="modal-scrollbar"
            class="dialog-style"
            style="height: 428px"
            :native="false"
            view-class="modal-container"
            :noresize="false"
            tag="div"
          >
            <div class="list">
              <el-checkbox-group v-model="checkedTwo" @change="cancelChecked">
                <el-checkbox v-for="(item, k) in checkList" :label="item[configs.value]" :key="k">{{
                  item[configs.label]
                }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </el-scrollbar>
        </div>
        <div class="dialog-footer">
          <el-button icon="el-icon-check" type="danger" @click="closeModal">关闭</el-button>
          <slot name="footer"> </slot>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '../../../../../../components/modal';
import request from '../../../../../../utils/request';

export default {
  name: 'checkList',
  props: {
    value: Array || String,
    disabled: Boolean,
    title: String,
    configs: Object,
  },
  data() {
    return {
      modalConfig: {
        type: 'Modal',
        title: '标题',
        visible: false,
        width: '50%',
      },
      checked: [],
      list: [],
      checkedTwo: [],
      checkList: [],
      loading: true,
    };
  },
  components: {
    Modal,
  },
  watch: {
    value(val) {
      if (val.length > 0 && this.loading) {
        let check = val;
        if (this.configs.value === 'fieldCode') {
          check = val.map((v) => v.fieldCode);
        }

        this.checked = check;
        this.checkedTwo = check;
        this.checkList = this.list.filter((v) => check.includes(v[this.configs.value]));
        this.loading = false;
      }
    },
  },
  methods: {
    // 打开弹框
    openFn() {
      this.modalConfig.title = this.title;
      this.modalConfig.visible = true;
    },
    // 关闭弹框
    closeModal() {
      this.modalConfig.visible = false;
    },
    // 获取列表
    getList() {
      request.post(this.configs.url, { ...this.configs.params, pageSize: 10000 }).then((res) => {
        if (res.success) {
          this.list = res.result.data;
        }
      });
    },
    // 选择监听
    checkedChange(val) {
      const filterList = this.list.filter((v) => val.includes(v[this.configs.value]));

      this.checkedTwo = val;
      this.checkList = filterList;

      let data = [];

      if (this.configs.value === 'fieldCode') {
        data = filterList.map((v) => ({
          fieldCode: v.fieldCode,
          fieldName: v.fieldName,
          whetherRequired: v.whetherRequired,
          displayOrder: v.displayOrder,
          defaults: v.defaults,
          hint: v.hint,
        }));
      } else {
        data = filterList.map((v) => v[this.configs.value]);
      }

      this.$emit('input', data);
    },
    // 移除监听
    cancelChecked(val) {
      const filterList = this.checkList.filter((v) => val.includes(v[this.configs.value]));

      this.checked = val;
      this.checkedTwo = val;

      this.checkList = filterList;

      let data = [];

      if (this.configs.value === 'fieldCode') {
        data = filterList.map((v) => ({
          fieldCode: v.fieldCode,
          fieldName: v.fieldName,
          whetherRequired: v.whetherRequired,
          displayOrder: v.displayOrder,
          defaults: v.defaults,
          hint: v.hint,
        }));
      } else {
        data = filterList.map((v) => v[this.configs.value]);
      }

      this.$emit('input', data);
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.list-main {
  height: 100%;
  display: flex;
  border: 1px solid #ccc;

  .list-item {
    flex: 1;
    box-sizing: border-box;

    + .list-item {
      border-left: 1px solid #ccc;
    }
  }

  .list-des {
    color: #9c9c9c;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }

  .el-checkbox {
    margin: 0 30px 15px 0;
  }
}
</style>
