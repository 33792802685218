<template>
  <div class="sub-table">
    <vxe-toolbar v-if="!disabled">
      <template v-slot:buttons>
        <el-button type="text" icon="el-icon-plus" @click="addTable">新增</el-button>
        <el-button type="text" icon="el-icon-delete" @click="deleteTable" style="color: #f56c6c">清空</el-button>
      </template>
    </vxe-toolbar>
    <vxe-table border show-overflow ref="xTable" :data="value">
      <vxe-table-column field="categoriesName" title="投入类型名称"></vxe-table-column>
      <vxe-table-column field="categoriesCode" title="投入类型编码"></vxe-table-column>
      <vxe-table-column v-if="!disabled" title="操作" width="60" fixed="right" align="center">
        <template #default="{ row, rowIndex }">
          <div class="setting-btn">
            <el-popconfirm
              title="此操作将永久删除该数据？"
              confirm-button-type="text"
              @confirm="removeRow(row, rowIndex)"
            >
              <el-button slot="reference" type="text">
                <i class="el-icon-delete" style="color: #f5222d"></i>
              </el-button>
            </el-popconfirm>
          </div>
        </template>
      </vxe-table-column>
    </vxe-table>
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import SelectConfig from '../../../../../../components/select_org_cus_ter/select_config.vue';

export default {
  name: 'Relation',
  props: {
    value: Array,
    disabled: Boolean,
  },
  data() {
    return {};
  },
  components: {
    SelectConfig,
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    // 弹框选择列表
    addTable() {
      const selectionList = this.value ? this.value.filter((v) => v.fineCode) : [];
      const params = {
        functionCode: 'tpm_relation_fine_list',
        data: this.value || [],
        selectionList,
        idKey: 'categoriesCode',
        paramData: {
          enableStatus: '009',
        },
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 选择数据回调
    onGetSelect(val) {
      const list = val.map((item) => {
        const v = item;
        delete v.id;
        return v;
      });
      this.$emit('input', list);
    },
    // 删除行数据
    removeRow(row, rowIndex) {
      // const index = this.$refs.xTable.getRowIndex(row);
      const list = this.value.filter((v, k) => k !== rowIndex);
      // this.value.forEach((item, k) => {
      //   if (index !== k) {
      //     list.push(item);
      //   }
      // });

      this.$emit('input', list);
    },
    // 清空表格数据
    deleteTable() {
      this.$emit('input', []);
    },
  },
};
</script>

<style lang="less" scoped>
.sub-table {
  padding-left: 30px;
  box-sizing: border-box;
}
</style>
