var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sub-table" },
    [
      !_vm.disabled
        ? _c("vxe-toolbar", {
            scopedSlots: _vm._u(
              [
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", icon: "el-icon-plus" },
                          on: { click: _vm.addTable },
                        },
                        [_vm._v("新增")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { color: "#f56c6c" },
                          attrs: { type: "text", icon: "el-icon-delete" },
                          on: { click: _vm.deleteTable },
                        },
                        [_vm._v("清空")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3650750238
            ),
          })
        : _vm._e(),
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: { border: "", "show-overflow": "", data: _vm.value },
        },
        [
          _c("vxe-table-column", {
            attrs: { field: "categoriesName", title: "投入类型名称" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "categoriesCode", title: "投入类型编码" },
          }),
          !_vm.disabled
            ? _c("vxe-table-column", {
                attrs: {
                  title: "操作",
                  width: "60",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        var rowIndex = ref.rowIndex
                        return [
                          _c(
                            "div",
                            { staticClass: "setting-btn" },
                            [
                              _c(
                                "el-popconfirm",
                                {
                                  attrs: {
                                    title: "此操作将永久删除该数据？",
                                    "confirm-button-type": "text",
                                  },
                                  on: {
                                    confirm: function ($event) {
                                      return _vm.removeRow(row, rowIndex)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-delete",
                                        staticStyle: { color: "#f5222d" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2600475598
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }