import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

import Relation, { Range, CheckList } from '../components';

formCreate.component('ActivityRelation', Relation);
formCreate.component('ActivityRange', Range);
formCreate.component('ActivityCheck', CheckList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'tpm_activity_type_form_jiulei',
    };
  },
  methods: {
    // 设置rule
    setRule(item) {
      const v = item;
      if (v.field === 'payTypeList') {
        v.props = {
          ...v.props,
          collapseTags: true,
        };
      } else if (v.field === 'extraAuditRatio') {
        v.props = {
          ...v.props,
          controls: false,
          precision: 2,
        };
        v.value = undefined;
      } else if (v.field === 'auditValidity') {
        v.props = {
          ...v.props,
          controls: false,
        };
        v.value = undefined;
      } else if (v.field === 'costFormCode') {
        v.restful = '/mdm/mdmfunctionsub/sub_list';
        v.optionsKey = { label: 'functionName', value: 'functionCode' };
        v.restfulParams = {
          functionCode: 'act-detail-template',
          // parentCode: 'CRM20201126000000093',
        };
      } else if (v.field === 'auditFormCode') {
        v.restful = '/mdm/mdmfunctionsub/sub_list';
        v.optionsKey = { label: 'functionName', value: 'functionCode' };
        v.restfulParams = {
          functionCode: 'audit-detail-template',
          // parentCode: 'CRM20201126000000093',
        };
      } else if (v.field === 'collectFieldList') {
        v.props = {
          ...v.props,
          title: '采集字段维护',
          configs: {
            url: '/tpm/tpmActCollectFieldController/list',
            label: 'fieldName',
            value: 'fieldCode',
          },
        };

        v.value = [];
      } else if (v.field === 'collectRequireList') {
        v.props = {
          ...v.props,
          title: '材料采集维护',
          configs: {
            url: '/tpm/tpmAuditCollectExampleController/list',
            label: 'exampleName',
            value: 'exampleCode',
            params: { exampleType: 'collect', enableStatus: '009' },
          },
        };
        v.value = [];
      } else if (v.field === 'auditRequireList') {
        v.props = {
          ...v.props,
          title: '核销资料维护',
          configs: {
            url: '/tpm/tpmAuditCollectExampleController/list',
            label: 'exampleName',
            value: 'exampleCode',
            params: { exampleType: 'audit', enableStatus: '009' },
          },
        };
        v.value = [];
      } else if (v.field === 'tpmCostTypeCategoriesFineReqVo') {
        v.value = [];
      } else if (v.field === 'rangeList') {
        v.value = {
          containOrgRangeVos: [],
          containOrgTypeRangeVos: [],
        };
      }
      return v;
    },
    // 表单渲染完成后回调
    formComplete() {
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request
          .get('/tpm/tpmCostTypeFineController/query', {
            id: this.formConfig.row.id,
          })
          .then((res) => {
            if (res.success) {
              const data = res.result;
              const {
                collectFieldList,
                collectRequireList,
                auditRequireList,
                tpmCostTypeCategoriesFineRespVo,
                containOrgRangeVos,
                containOrgTypeRangeVos,
              } = data;

              // 采集字段维护
              data.collectFieldList = collectFieldList || [];

              // 材料采集维护
              data.collectRequireList = JSON.parse(collectRequireList) || [];

              // 核销资料维护
              data.auditRequireList = JSON.parse(auditRequireList) || [];

              // 关联投入类型
              data.tpmCostTypeCategoriesFineReqVo = tpmCostTypeCategoriesFineRespVo ? tpmCostTypeCategoriesFineRespVo.map(
                (item) => {
                  const v = item;
                  v.editId = v.id;
                  return v;
                },
              ) : [];

              // 范围信息
              data.rangeList = {
                containOrgRangeVos: containOrgRangeVos ? containOrgRangeVos.map((item) => {
                  const v = item;
                  v.orgCode = v.rangeCode;
                  v.orgName = v.rangeName;
                  v.editId = v.id;
                  return v;
                }) : [],
                containOrgTypeRangeVos: containOrgTypeRangeVos ? containOrgTypeRangeVos.map((item) => {
                  const v = item;
                  v.dictCode = v.rangeCode;
                  v.dictValue = v.rangeName;
                  v.editId = v.id;
                  return v;
                }) : [],
              };

              data.payTypeList = JSON.parse(data.payTypeList);
              this.setValue(data);
            }
          });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值

      if (formData) {
        let url = '/tpm/tpmCostTypeFineController/save';
        const params = { ...formData, ...formData.rangeList };

        const { collectRequireList, auditRequireList, payTypeList } = formData;

        params.tpmProjectName = 'liqueurTpm';

        // 材料采集维护
        params.collectRequireList = collectRequireList.length > 0 ? JSON.stringify(collectRequireList) : null;
        // 核销资料维护
        params.auditRequireList = auditRequireList.length > 0 ? JSON.stringify(auditRequireList) : null;
        // 支付类型
        params.payTypeList = JSON.stringify(payTypeList);

        // 范围信息
        params.containOrgRangeVos = params.containOrgRangeVos.map((item) => {
          const v = item;
          if (v.editId) {
            v.id = v.editId;
            delete v.editId;
          }
          return v;
        });
        params.containOrgTypeRangeVos = params.containOrgTypeRangeVos.map((item) => {
          const v = item;
          if (v.editId) {
            v.id = v.editId;
            delete v.editId;
          }
          return v;
        });

        // 关联投入类型
        params.tpmCostTypeCategoriesFineReqVo = params.tpmCostTypeCategoriesFineReqVo.map((item) => {
          const v = item;
          if (v.editId) {
            v.id = v.editId;
            delete v.editId;
          }
          return v;
        });

        if (this.formConfig.code === 'edit') {
          url = '/tpm/tpmCostTypeFineController/update';
          params.id = this.formConfig.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
